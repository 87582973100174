import React from "react"
import { FaRegClock, FaMapMarkerAlt } from "react-icons/fa"
import CalendarLeaf from "./calendar-leaf"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import PropTypes from "prop-types"

const EventDetail = props => {
  const event = props.event
  const startDate = new Date(
    event.start.dateTime ? event.start.dateTime : event.start.date
  )
  const endDate = new Date(
    event.end.dateTime ? event.end.dateTime : event.end.date
  )

  const prettyTime = datetime => {
    let hh = datetime.getHours()
    if (hh < 10) {
      hh = "0" + hh
    }
    let mm = datetime.getMinutes()
    if (mm < 10) {
      mm = "0" + mm
    }
    return `${hh}:${mm}`
  }

  const createMarkup = htmlString => {
    return { __html: htmlString }
  }

  return (
    <>
      <Row className={props.className}>
        <Col sm={4} className="">
          <div className="d-flex justify-content-center mb-2">
            <CalendarLeaf start={startDate} end={endDate} />
          </div>
        </Col>
        <Col sm={8}>
          <div className="text-center text-sm-left">
            <h3 className={"mb-1 mt-0"}>{event.summary}</h3>
            <div className="font-color-medium">
              <span className="mr-4">
                <FaRegClock />{" "}
                {prettyTime(startDate) + " - " + prettyTime(endDate)}
              </span>
              {event.location ? (
                <span>
                  <FaMapMarkerAlt />
                  {event.location}
                </span>
              ) : (
                ""
              )}
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col sm={4} />
        <Col sm={8}>
          <div dangerouslySetInnerHTML={createMarkup(event.description)} />
        </Col>
      </Row>
    </>
  )
}

EventDetail.propTypes = {
  className: PropTypes.string,
  event: PropTypes.any,
}

EventDetail.defaultProps = {
  className: ``,
  event: undefined,
}

export default EventDetail
